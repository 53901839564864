var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_wrap"},[_c('h1',{staticClass:"h_none"},[_vm._v("商标管理系统")]),_c('h2',{staticClass:"h_none"},[_vm._v("蓝灯鱼商标管理、商标、流程化管理")]),_c('img',{staticClass:"h_none",attrs:{"src":"/images/result_show_logo.png","alt":""}}),_vm._m(0),_vm._m(1),_c('section',{staticClass:"subject"},[_vm._m(2),_vm._m(3),_c('el-popover',{staticClass:"contact",attrs:{"placement":"top","width":"350","trigger":"hover"}},[(_vm.isCN)?_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian.jpg","alt":""}}):_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian_en.png","alt":""}}),_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("联系我们")])],1)],1),_c('section',{staticClass:"sub_subject"},[_c('div',{staticClass:"subject patent"},[_vm._m(4),_vm._m(5),_vm._m(6),_c('el-popover',{staticClass:"contact",attrs:{"placement":"top","width":"350","trigger":"hover"}},[(_vm.isCN)?_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian.jpg","alt":""}}):_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian_en.png","alt":""}}),_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("联系我们")])],1)],1)]),_c('footers'),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"custom-class":"login_dialog","before-close":_vm.closeEvent},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('login',{attrs:{"dialog-close":_vm.dialogClose},on:{"loginDialog":_vm.loginDialog,"dialogCloseBool":_vm.dialogCloseBool}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_banner"},[_c('i',{staticClass:"index_banner_light"}),_c('i',{staticClass:"index_banner_lightT"}),_c('div',{staticClass:"index_banner_title"},[_c('p',[_vm._v("专利管理系统")]),_c('p',[_vm._v("提供企业/机构"),_c('span',[_vm._v("多样化可定制")]),_vm._v("的专利业务解决方案")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"top"},[_c('span',[_vm._v(" 专利管理系统 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_left"},[_c('img',{attrs:{"src":require("../../assets/images/patent/inquiry.png"),"alt":""}}),_c('div',{staticClass:"subject_left_rud"},[_c('p',[_vm._v(" 产品优势：智能化、标准化、灵活配置"),_c('br'),_vm._v(" 案件流程全生命周期管理   ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_right"},[_c('div',{staticClass:"subject_right_title"},[_vm._v(" 面向专利代理机构，案件流程全生命周期管理 ")]),_c('ul',[_c('li',[_vm._v(" 产品是一款适用于不同发展阶段代理机构的专利案件全生命周期业务管理系统。 ")]),_c('li',[_c('span',{staticClass:"characteristics"},[_vm._v("自身特点：")]),_vm._v("可定制的标准流程管理、无缝对接官方系统，对接CPC自动收文， 智能任务流程触发，稽核内部案件信息；弹性配置规则库，高效管理各种类型专利案件、自动时限监控、灵活信函模板配置、 多角色权限管理，多维度综合查询、支持列表自定义精细查询。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top patent",staticStyle:{"width":"258px"}},[_c('span',[_vm._v(" 专利年费管理系统 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_left"},[_c('img',{attrs:{"src":require("../../assets/images/patent/inquiry.png"),"alt":""}}),_c('div',{staticClass:"subject_left_rud"},[_c('p',{staticClass:"text_item1"},[_vm._v(" 产品优势： ")]),_c('div',{staticClass:"text_item2"},[_vm._v(" 年费报告自动生成"),_c('br'),_vm._v(" 自动监控缴费提醒"),_c('br'),_vm._v(" 自定义国内外年费配置"),_c('br'),_vm._v(" 年费数据批量导入导出 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_right"},[_c('div',{staticClass:"subject_right_title"},[_vm._v(" 专利年费全生命周期缴费及缴费时限管理 ")]),_c('ul',[_c('li',[_vm._v(" 蓝灯鱼年费管理系统是一款可以单独使用或搭配专利管理系 统完成国内外专利年费管理和监控的系统。 ")]),_c('li',[_c('span',{staticClass:"characteristics"},[_vm._v("自身特点：")]),_vm._v("灵活安全的商标案卷及流程数据管理，任务办案"),_c('br'),_vm._v("时限自动提醒，自动生成报告、信函模板，自动官申提交， 便捷的信函模板和文件模板配置，灵活的权限配置。 ")])])])
}]

export { render, staticRenderFns }
<template>
  <div class="index_wrap">
    <h1 class="h_none">商标管理系统</h1>
    <h2 class="h_none">蓝灯鱼商标管理、商标、流程化管理</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
      <div class="index_banner_title">
        <p>专利管理系统</p>
        <p>提供企业/机构<span>多样化可定制</span>的专利业务解决方案</p>
      </div>
    </div>

    <section class="top">
      <span>
        专利管理系统
      </span>
    </section>
    <section class="subject">
      <!--      inquiry.png-->
      <div class="subject_left">
        <img src="../../assets/images/patent/inquiry.png" alt="">
        <div class="subject_left_rud">
          <p>
            产品优势：智能化、标准化、灵活配置<br/>
            案件流程全生命周期管理&nbsp;&nbsp;
          </p>
        </div>
      </div>

      <div class="subject_right">
        <div class="subject_right_title">
          面向专利代理机构，案件流程全生命周期管理
        </div>
        <ul>
          <li>
            产品是一款适用于不同发展阶段代理机构的专利案件全生命周期业务管理系统。
          </li>
          <li>
            <span class="characteristics">自身特点：</span>可定制的标准流程管理、无缝对接官方系统，对接CPC自动收文，
            智能任务流程触发，稽核内部案件信息；弹性配置规则库，高效管理各种类型专利案件、自动时限监控、灵活信函模板配置、
            多角色权限管理，多维度综合查询、支持列表自定义精细查询。
          </li>
        </ul>
      </div>

      <el-popover
        class="contact"
        placement="top"
        width="350"
        trigger="hover">
        <img v-if="isCN" style="width: 100%;height: auto" src="/images/index/mpian.jpg" alt="">
        <img v-else style="width: 100%;height: auto" src="/images/index/mpian_en.png" alt="">
        <el-button type="primary" slot="reference">联系我们</el-button>
      </el-popover>
    </section>

    <section class="sub_subject">
      <div class="subject patent">
        <div style="width: 258px" class="top patent">
          <span>
            专利年费管理系统
          </span>
        </div>
        <!--      inquiry.png-->
        <div class="subject_left">
          <img src="../../assets/images/patent/inquiry.png" alt="">
          <div class="subject_left_rud">
            <p class="text_item1">
              产品优势：
            </p>
            <div class="text_item2">
              年费报告自动生成<br/>
              自动监控缴费提醒<br/>
              自定义国内外年费配置<br/>
              年费数据批量导入导出
            </div>
          </div>
        </div>

        <div class="subject_right">
          <div class="subject_right_title">
            专利年费全生命周期缴费及缴费时限管理
          </div>
          <ul>
            <li>
              蓝灯鱼年费管理系统是一款可以单独使用或搭配专利管理系
              统完成国内外专利年费管理和监控的系统。
            </li>
            <li>
              <span class="characteristics">自身特点：</span>灵活安全的商标案卷及流程数据管理，任务办案<br/>时限自动提醒，自动生成报告、信函模板，自动官申提交，
              便捷的信函模板和文件模板配置，灵活的权限配置。
            </li>
          </ul>
        </div>
        <el-popover
          class="contact"
          placement="top"
          width="350"
          trigger="hover">
          <img v-if="isCN" style="width: 100%;height: auto" src="/images/index/mpian.jpg" alt="">
          <img v-else style="width: 100%;height: auto" src="/images/index/mpian_en.png" alt="">
          <el-button type="primary" slot="reference">联系我们</el-button>
        </el-popover>
      </div>
    </section>

    <footers></footers>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import login from "@/components/common/login"

  export default {
    name: 'index',
    metaInfo: {
      title: '商标管理系统', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标管理系统、商标、管理系统、商标数据、商标信息、流程化管理'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼商标管理系统是一款面向「商标代理机构」提供「全类型」商标业务的流程管理系统。可灵活安全的商标案卷及流程数据管理，任务办案时限自动提醒，自动生成报告、信函模板，自动官申提交，便捷的信函模板和文件模板配置，灵活的权限配置。'
      }],
    },
    data() {
      return {
        isCN: true,
        dialogClose: false,
        dialogVisible: false,
        listData: [
          {
            categoryCode: "shangbiaoyewu",
            categoryName: "商标业务",
            currency: "CNY",
            existApply: false,
            imageUrl: "patent_mgr_system.png",
            modeType: "4",
            price: '全球数据 周期自选',
            productName: "专利翻译",
            publishType: "4",
            serviceDesc: "流程化管理",
            button: true,
            btnName: "购买",
            serviceName: "商标管理系统"
          }
        ],
        //走马灯图片名
        name: '',
        lun: [{
          imgSrc: '/images/index/query_index1.png',
          name: '动漫检索'
        }]
      }
    },
    mounted() {
      if (this.$i18n.locale === 'cn') {
        this.isCN = true
      } else {
        this.isCN = false
      }
      // if (this.$route.query.point) {
      //   if (this.$route.query.point == 0) {
      //     $('body,html').scrollTop(0)
      //   } else if (this.$route.query.point == 1) {
      //     setTimeout(() => {
      //       $('body,html').scrollTop(1240)
      //     },200)
      //   }
      // }
    },
    watch: {
      '$route'() {
        if (this.$route.query.point) {
          if (this.$route.query.point == 0) {
            $('body,html').scrollTop(0)
          } else if (this.$route.query.point == 1) {
            this.$nextTick(() => {
              $('body,html').scrollTop(1240)
            })

          }
        }
      }
    },
    components: {
      footers,
      login
    },
    methods: {
      //产品定价
      btn(item) {
        if (this.tool.getCookie("auth")) {
          if (item.btnName == "购买") {
            this.$router.push({
              path: '/personalCenter/productService',
              query: {
                typeId: 27
              }
            })
          } else {

            // this.applicaEvent()
          }
        } else {
          this.dialogVisible = true
        }
      },
      //走马灯
      select(item) {
        this.name = this.lun[item].name
      },
      immediate() {
        this.$router.push({
          path: '/PT'
        })
      },
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;

    .index_banner {
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}patent/pmsbanner.png") no-repeat center
        center/auto 100%;
      position: relative;

      .index_banner_light {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/pmsbanner.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }

      .index_banner_lightT {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/pmsbanner.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }

      .index_banner_title {
        position: absolute;
        left: 18%;
        top: 282px;
        z-index: 3;

        i {
          display: block;
          width: 343px;
          margin-bottom: 30px;

          img {
            display: block;
            width: 100%;
          }
        }

        p:nth-child(1) {
          font-size: 56px;
          line-height: 56px;
          font-weight: 600;
          color: #131313;
          letter-spacing: 2px;
        }

        p:nth-child(2) {
          font-size: 30px;
          font-weight: 500;
          color: #091567;
          line-height: 30px;
          letter-spacing: 1px;
          margin-top: 48px;

          span {
            color: rgba(34, 77, 232, 1)
          }
        }

        .el_button {
          margin-top: 30px;
          width: 123px;
          height: 41px;
          font-size: 18px;
          color: rgb(27, 48, 191);
          font-weight: bold;
        }
      }
    }

    .top {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 238px;
      padding-top: 46px;
      margin: 0 auto;
      margin-bottom: 51px;
      span {
        position: relative;
        z-index: 999;
      }

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 238px;
        height: 12px;
        background: rgba(38, 77, 216, 0.58);
        border-radius: 5px;
      }

      &.patent:before {
        left: 11px;
      }
    }

    .subject {
      width: 1202px;
      margin: 0 auto;
      position: relative;

      .subject_left {
        height: 630px;
        img {
          position: relative;
          z-index: 5;
          width: 512px;
          height: 330px;
        }
        .subject_left_rud {
          top: -201px;
          left: 268px;
          position: relative;
          z-index: 4;
          width: 410px;
          height: 410px;
          background: #CCD8FF;
          font-size: 20px;
          font-weight: 600;
          color: #091567;
          border-radius: 50%;
          line-height: 42px;

          p {
            position: absolute;
            text-align: right;
            bottom: 93px;
            left: 40px

          }
        }
      }

      .subject_right {
        width: 550px;
        position: absolute;
        top: 34px;
        right: 0;

        .subject_right_title {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
        }

        ul {
          width: 470px;
          top: 65px;
          right: 0;
          height: 156px;
          text-align: left;
          position: absolute;

          li {
            font-size: 18px;
            font-weight: 400;
            color: #555555;
            line-height: 36px;

            &:nth-of-type(1) {
              position: relative;

              &:after {
                content: '';
                width: 463px;
                height: 2px;
                bottom: -32px;
                position: absolute;
                left: 0;
                background: rgba(151, 151, 151, 0.47);
              }
            }

            &:nth-of-type(2) {
              margin-top: 68px !important;
            }

            .characteristics {
              color: #3c3d41;
              font-weight: bold;
            }
          }
        }
      }

      .contact {
        position: absolute;
        bottom: 100px;
        right: 0;
      }
    }

    .sub_subject {
      background: white;

      .subject.patent {
        background: white;
        width: 1202px;
        height: 800px;
        margin: 0 auto;
        position: relative;

        .subject_left {
          position: absolute;
          right: 0;

          img {
            position: relative;
            z-index: 5;
            width: 512px;
            height: 330px;
          }

          .subject_left_rud {
            top: -190px;
            left: -190px;
            position: relative;
            z-index: 4;
            width: 410px;
            height: 410px;
            background: #CCD8FF;
            font-size: 20px;
            font-weight: 600;
            color: #091567;
            border-radius: 50%;
            line-height: 42px;
            display: flex;

            .text_item1 {
              position: absolute;
              text-align: right;
              bottom: 184px;
              left: 35px;
            }

            .text_item2 {
              position: absolute;
              text-align: center;
              width: 410px;
              bottom: 57px;
              right: -20px;
            }
          }
        }

        .subject_right {
          width: 550px;
          position: absolute;
          top: 189px;
          left: 0;

          .subject_right_title {
            padding-left: 69px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }

          ul {
            width: 470px;
            top: 65px;
            left: 0;
            height: 156px;
            text-align: left;
            position: absolute;

            li {
              font-size: 18px;
              font-weight: 400;
              color: #555555;
              line-height: 36px;

              &:nth-of-type(1) {
                position: relative;

                &:after {
                  content: '';
                  width: 463px;
                  height: 2px;
                  bottom: -32px;
                  position: absolute;
                  left: 0;
                  background: rgba(151, 151, 151, 0.47);
                }
              }

              &:nth-of-type(2) {
                margin-top: 68px !important;
              }

              .characteristics {
                color: #3c3d41;
                font-weight: bold;
              }
            }
          }
        }

        .contact {
          position: absolute;
          bottom: 100px;
          right: 0;
        }
      }
    }

  }

  @media screen and (max-width: 1600px) {
    .index_wrap {
      .index_banner {
        height: 460px;

        .index_banner_title {
          top: 200px;
          left: 12%;
        }
      }

      .learnMore {
        margin-top: 110px !important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .index_wrap {
      .index_banner {
        /*height: 602px;*/

        .index_banner_title {
          top: 200px;
          left: 8%;
        }
      }
    }
  }

  .arrow-enter {
    transform: translateX(-30px);
  }

  .arrow-enter-active {
    transition: all 0.8s ease;
  }
</style>
